"use client";
import React, { useContext } from "react";
import { Context } from "@context/store.jsx";
import { DayPicker } from "react-day-picker";
import dayjs from "dayjs";
import "react-day-picker/style.css";

const numberPositionMap = [
  3.33, 11.66, 20, 28.32, 37.65, 44.98, 53.31, 61.64, 70, 78.3, 87.63, 95,
];
export default function DurationDatePicker() {
  const [{ bookingInfo }, dispatch] = useContext(Context);
  const { start_date, end_date, month_count } = bookingInfo;
  const updateMonthCount = (value) => {
    dispatch({
      type: "bookingInfo",
      payload: {
        ...bookingInfo,
        month_count: value,
        end_date: dayjs(start_date).add(value, "month").toString(),
      },
    });
  };

  const updateStartDate = (value) => {
    if (value) {
      const newStartDate = dayjs(value).startOf("day").toDate();
      const newEndDate = dayjs(value)
        .add(month_count ?? 1, "month")
        .startOf("day")
        .toDate();

      dispatch({
        type: "bookingInfo",
        payload: {
          ...bookingInfo,
          start_date: newStartDate,
          end_date: newEndDate,
        },
      });
    }
  };

  return (
    <div className="duration-date-picker">
      <DayPicker
        mode="single"
        selected={new Date(start_date)}
        onSelect={(date) => updateStartDate(date)}
        disabled={[
          { before: new Date() },
          { after: dayjs().add(2, "year").toDate() },
        ]}
        className="day-picker"
        style={{}}
        modifiersStyles={{
          selected: {
            backgroundColor: "#000",
            // fill: "#000",
            color: "#fff",
            borderRadius: "50%",
            border: "none",
          },
          today: {
            color: "#000",
          },
        }}
      />
      <h3>
        <span>Check Out: </span>
        {end_date ? dayjs(end_date).format("DD-MM-YYYY") : ""}
      </h3>
      <div className="months-slider">
        <div
          className="custom-range-container"
          style={{ position: "relative", width: "100%" }}
        >
          <input
            type="range"
            min="1"
            max="12"
            value={month_count || 1}
            onChange={(e) => updateMonthCount(Number(e.target.value))}
            style={{
              width: "100%",
              opacity: 0,
              position: "absolute",
              cursor: "pointer",
            }}
          />
          <div
            className="range-track"
            style={{
              height: "20px",
              background: "#E5E7EB",
              width: "100%",
              borderRadius: "10px",
            }}
          >
            <div
              className="range-progress"
              style={{
                height: "20px",
                background: "black",
                width: `${((month_count ?? 1) / 12) * 100}%`,
                borderRadius: "10px",
              }}
            />
          </div>
          <div
            className="range-knob"
            style={{
              position: "absolute",
              left: `${numberPositionMap[(month_count ?? 1) - 1]}%`,
              transform: "translate(-50%, -50%)",
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              background: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              fontSize: "16px",
              pointerEvents: "none",
              top: "50%",
              border: "1px solid #000",
              zIndex: 10,
            }}
          >
            {month_count}
          </div>
        </div>
      </div>
    </div>
  );
}
