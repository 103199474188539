import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Context } from "@context/store.jsx";
import { useAxiosPrivate } from "@api/axios.jsx";
import { usePathname } from "next/navigation";
import Image from "next/image";

export default function LocationPicker() {
  const [filteredLocations, setFilteredLocations] = useState({});
  const [allProperties, setAllProperties] = useState([]);
  const [{ bookingInfo, allPropertiesAvailability }, dispatch] =
    useContext(Context);

  const pathname = usePathname();
  const getAllProperties = async () => {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_SERVER_BASE_URL}/api/guest/property/getLocations`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-store",
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const availableLocations = data.data;
    setFilteredLocations(availableLocations);
  };
  useEffect(() => {
    getAllProperties();
  }, []);

  const handleSelectLocation = (location, city) => {
    dispatch({
      type: "bookingInfo",
      payload: {
        ...bookingInfo,
        location: {
          location,
          city,
        },
      },
    });
  };

  return (
    <>
      {Array.isArray(filteredLocations) &&
        filteredLocations.map((item, index) => (
          <li
            key={index}
            onClick={() => handleSelectLocation(item.location, "")}
          >
            <div>
              <Image
                width={24}
                height={24}
                alt="pinDropHome"
                src="/assets/icons/svgs/pinDropHome.svg"
              />
              <h5>
                {item.location === "all" ? "All" : item.location}{" "}
                <span>({item.total})</span>
                {bookingInfo.location?.location === item.location && (
                  <FontAwesomeIcon icon={faCheck} className="check-mark" />
                )}
              </h5>
            </div>
            {item.subLocation && (
              <ul>
                {item.subLocation.map((subItem, subIndex) => (
                  <li
                    key={subIndex}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectLocation(item.location, subItem.location);
                    }}
                  >
                    <div>
                      <Image
                        width={24}
                        height={24}
                        alt="pinDropHome"
                        src="/assets/icons/svgs/pinDropHome.svg"
                      />
                      <h5>
                        {subItem.location}
                        <span>({subItem.count})</span>
                        {bookingInfo.location?.city === subItem.location && (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="check-mark"
                          />
                        )}
                      </h5>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
    </>
  );
}
